import styled from '@emotion/styled'
import MEDIA from '../helpers/mediaTemplates'
import { colors } from '../utils/styles'

export const PageContainerStyles = styled.div`
  /* @media (min-width: 576px) { } */
  /* @media (min-width: 768px) { ... } */
  /* @media (min-width: 992px) { ... } */
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 1000px;
  }
`
export const FullPageStyles = styled.div`
  display: flex;
  min-height: 700px;
  flex: 1;
  //justify-content: center;
  /* @media (min-width: 576px) { } */
  /* @media (min-width: 768px) { ... } */
  /* @media (min-width: 992px) { ... } */
  @media (min-width: 1200px) {
    width: 100%;
  }
`
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  max-height: 400px;
  width: 100%;

  h3 {
    flex: 1;
    font-family: Noto Sans, serif;
    font-size: 1.5rem;
    font-weight: 700;

    border-bottom: 2px dotted red;
    text-align: center;
    margin-bottom: 1rem;
  }

  h2 {
    flex: 1;
    text-align: center;
  }

  p {
    flex: 1;
    color: black;
    margin: 0 1rem 1rem;
    font-size: 1.5rem;
    text-align: justify;
    line-height: 1.5;

    ${MEDIA.TABLET`
    margin-bottom: 0.5rem;
  `}
    em,span {
      color: ${colors.brand};
      font-family: Gloria Hallelujah, cursive;
    }
    em {
      font-size: 2rem;
    }
    span {
      font-size: 3rem;
    }
  }
  span::first-letter,
  p::first-letter {
    font-size: 3rem;
    color: ${colors.brand};
    font-family: Gloria Hallelujah, cursive;
  }

  .locations {
    flex: 1;
    text-align: center;
    font-size: 1.5em;
    line-height: 1;
  }

  .locations:before,
  .locations:after {
    content: '*';
    font-size: 3rem;
    color: ${colors.brand};
    font-family: Gloria Hallelujah, cursive;
  }
`

export const StyledSectionTwo = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  justify-content: center;
  text-align: justify;
  word-wrap: break-word;
  padding-right: 20px;

  em,
  span {
    font-size: 2rem;
    color: ${colors.brand};
    font-family: Gloria Hallelujah, cursive;
  }
  // em {
  //   font-size: 2rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }
  //
  // span {
  //   font-size: 2rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }
  span::first-letter,
  u::first-letter,
  li::before {
    font-size: 3rem;
    color: ${colors.brand};
    font-family: Gloria Hallelujah, cursive;
  }

  span::first-letter {
    font-size: 2rem;
    // color: ${colors.brand};
    // font-family: Gloria Hallelujah, cursive;
  }

  // u::first-letter {
  //   font-size: 3rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }

  li::before {
    content: '*';
    // font-size: 3rem;
    // color: ${colors.brand};
    // font-family: Gloria Hallelujah, cursive;
  }

  /* @media (min-width: 576px) { } */
  /* @media (min-width: 768px) { ... } */
  /* @media (min-width: 992px) { ... } */
  @media (min-width: 1200px) {
    margin-bottom: 80px;
  }
`
export const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  /* @media (min-width: 576px) { } */
  /* @media (min-width: 768px) { ... } */
  /* @media (min-width: 992px) { ... } */
  @media (min-width: 1200px) {
    margin-bottom: 80px;
  }
`
export const PageTitleWrapperStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* @media (min-width: 576px) { } */
  /* @media (min-width: 768px) { ... } */
  /* @media (min-width: 992px) { ... } */
  /* @media (min-width: 1200px) {  } */
`
export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  max-height: 400px;
  width: 100%;
  @media (min-width: 576px) {
    flex-direction: row;
  }

  h2 {
    flex: 1;
    text-align: center;
  }

  h3 {
    flex: 1;
    font-family: Noto Sans, serif;
    font-size: 1.5rem;
    font-weight: 700;

    border-bottom: 2px dotted rgb(242, 176, 200);
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    flex: 1;
    color: black;
    margin: 0 1rem 1rem;
    font-size: 1.5rem;
    text-align: justify;
    line-height: 1.5;

    ${MEDIA.TABLET`
    margin-bottom: 0.5rem;
  `}
    em,span {
      color: ${colors.brand};
      font-family: Gloria Hallelujah, cursive;
    }

    em {
      font-size: 2rem;
    }
    span {
      font-size: 3rem;
    }
  }

  strong,
  span::first-letter,
  p::first-letter,
  li::before {
    color: ${colors.brand};
    font-family: Gloria Hallelujah, cursive;
    font-size: 3rem;
  }

  // strong {
  //   font-size: 2rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }
  //
  // span::first-letter {
  //   font-size: 3rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }
  //
  // p::first-letter {
  //   font-size: 3rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }

  ul {
    list-style: none;
  }

  li::before {
    content: '*';
    // font-size: 3rem;
    // color: ${colors.brand};
    // font-family: Gloria Hallelujah, cursive;
  }

  .locations {
    flex: 1;
    text-align: center;
    font-size: 1rem;
  }

  .locations::before,
  .locations::after {
    content: '*';
    font-size: 3rem;
    color: ${colors.brand};
    font-family: Gloria Hallelujah, cursive;
  }

  // .locations::after {
  //   content: '*';
  //   font-size: 3rem;
  //   color: ${colors.brand};
  //   font-family: Gloria Hallelujah, cursive;
  // }
`
export const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
`

export const InlineFlex = styled(Flex)`
  width: 60%;
  display: inline-flex;
  justify-content: space-around;
`
export const FlexColumn = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
