export const spacing = {
  '2xl': 40,
  '2xs': 4,
  '3xl': 48,
  '3xs': 2,
  sm: 12,
  xs: 8,
  md: 16,
  lg: 24,
  xl: 32,
}

export const radius = {
  default: 2,
  large: 4,
}
export const colors = {
  brandDarker: '#486A74',
  brandDark: '#6B9EAE',
  brand: 'rgb(242, 176, 200)',
  brandBright: '#e0d6eb',
  brandLight: '#f5f3f7',
  brandLighter: 'rgba(253 231 239)',
  lightest: '#ffffff',
  darkest: '#4d4058',
  text: '#333333',
  textMild: '#555555',
  textLight: '#7e718a',
  textLighter: '#aaaaaa',
  lilac: '#8c65b3',
  accent: '#ffb238',
  error: '#ec1818',
  lemon: '#ffdf37',
  green: '#12BC00',
  teal: '#00B0BD',
  darkTeal: '#005A71',
  pink: '#FBD7E5',
  pinkDark: '#F8B1CB',
  black: '#393939',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',
  grey: '#3A3A3A',
  lightgrey: '#E1E1E1',
  offWhite: '#EDEDED',
  red: '#FF0000',
}
export const theme = {
  black: '#393939',
  brandColor: colors.brand,
  brandFont: 'Gloria Hallelujah, cursive',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',
  grey: '#3A3A3A',
  lightgrey: '#E1E1E1',
  maxWidth: '1000px',
  offWhite: '#EDEDED',
  red: '#FF0000',
}
