import React from 'react'
import styled from '@emotion/styled'

const StyledFooter = styled.footer`
  padding: 0 24px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #f8f9fa;
  color: #a1a1a1;
  line-height: 50px;
  min-height: 50px;
  height: 50px;
  max-height: 50px;

  a {
    color: #a1a1a1;
  }

  a:hover {
    color: #fff;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    padding: 0 20px;
  }
`

const Footer = () => (
  <StyledFooter>
    <div style={{ display: 'flex' }}>
      <div>
        <span>
          Copyright The Doggie Detailer, LLC &copy; {new Date().getFullYear()}
        </span>
      </div>
    </div>
  </StyledFooter>
)
export default Footer
