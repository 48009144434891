import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { colors } from '../../utils/styles'

const LogoContainer = styled.div`
  //padding-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`
const LogoText = styled.h1`
  flex: 1 1 auto;
  font-size: 2.5rem;
  transform: skew(-7deg);
  font-family: Gloria Hallelujah, cursive;
  transition: color 0.2s ease;
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  padding-left: 2vw;

  a {
    padding: 0.5rem 1rem;
    color: ${colors.brand};
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    text-align: center;
    font-size: 1.5em;
  }
`
const Logo = ({ navbarOpen }) => (
  <LogoContainer>
    <LogoText open={!navbarOpen}>
      <a href="/">The Doggie Detailer</a>
    </LogoText>
  </LogoContainer>
)
Logo.propTypes = {
  navbarOpen: PropTypes.bool,
}
export default Logo
