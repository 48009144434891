import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { colors } from '../../utils/styles'

const SocialLinks = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  flex-direction: row;

  .social {
    flex: 1;
    padding: 0 20px;
  }
`
const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  //font-size: 1.2rem;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    content: '.';
    color: transparent;
    background: ${colors.brand};
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: ${colors.brand};

    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    //font-size: 1.5rem;
    z-index: 6;
  }
`

const SocialLink = styled.a``
const NavbarLinks = () => (
  <>
    <NavItem to="/">Home</NavItem>
    <NavItem to="/services">Services</NavItem>
    <NavItem to="/policies">Policies</NavItem>
    <NavItem to="/contact">Contact</NavItem>
    <SocialLinks>
      <li className="social">
        <SocialLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/doggiedetailernj"
        >
          <i className="fa fa-instagram" />
        </SocialLink>
      </li>
    </SocialLinks>
  </>
)

export default NavbarLinks
