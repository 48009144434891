import React, { useState } from 'react'
import styled from '@emotion/styled'

import NavbarLinks from './NavbarLinks'
import Logo from './Logo'

const Navigation = styled.nav`
  height: 10vh;
  display: flex;
  background-color: #fff;
  position: relative;
  justify-content: space-between;
  text-transform: uppercase;
  //border-bottom: 2px solid #33333320;
  margin: 0 auto;
  padding: 0 5vw 0 2vw;
  z-index: 2;
  align-self: center;
  @media (max-width: 768px) {
    position: sticky;
    //height: 8vh;
    top: 0;
    left: 0;
    right: 0;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 3vw;
  @media (max-width: 768px) {
    display: flex;
  }
`

const NavBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 8vh;
    left: ${props => (props.open ? '-100%' : '0')};
  }
`

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`

const StyledHeader = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  background: #fff;

  .bar {
    display: flex;
    margin: 0 auto;
    justify-self: center;
    justify-content: center;
    align-items: center;
  }
`
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <StyledHeader>
      <Logo navbarOpen={navbarOpen} />
      <div className="bar">
        <Navigation>
          <Toggle
            navbarOpen={navbarOpen}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
          {navbarOpen ? (
            <NavBox>
              <NavbarLinks />
            </NavBox>
          ) : (
            <NavBox open>
              <NavbarLinks />
            </NavBox>
          )}
        </Navigation>
      </div>
    </StyledHeader>
  )
}

export default Navbar
