import React from 'react'
import PropTypes from 'prop-types'
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Global, ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import Navbar from './NavBar/Navbar'
import { theme } from '../utils/styles'
import Footer from './footer'
import SEO from './seo'

const StyledPage = styled.div`
  background: white;
  color: ${props => props.theme.black};
`

const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};

  margin: 0 auto;
  @media (max-width: 576px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`
const globalStyles = css`
  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2;
  }

  a {
    text-decoration: none;
    color: ${theme.black};
  }

  @media print {
    body * {
      visibility: hidden;
    }

    #printThis,
    #printThis * {
      visibility: visible;
    }

    #printThis {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 2vw;
      padding-right: 2vw;
    }
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <Global styles={globalStyles} />
      <StyledPage>
        <SEO />
        <Navbar />
        <Inner>{children}</Inner>
        <Footer />
      </StyledPage>
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
